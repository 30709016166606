<template>
  <div class="article-list-view sw-pt-11">
    <v-container fluid>
      <transition name="fade">
        <div v-if="isDataLoaded">
          <v-card flat max-width="1380" class="mx-auto">
            <div class="sw-page-title">
              {{ navigation.name }}
            </div>
            <div v-if="navigation.description" class="sw-mb-9">
              <div class="sw-page-subtitle">{{ navigation.description }}</div>
            </div>
            <v-row class="no-gutters sw-mx-n3">
              <v-col
                v-for="(article, i) in articles"
                :key="`article-${i}`"
                cols="3"
                class="sw-mb-6 sw-px-3"
              >
                <v-card min-height="262" class="mx-auto fill-height rounded-lg">
                  <v-row
                    no-gutters
                    class="fill-height"
                    :class="{ 'align-center': !article.article_image }"
                  >
                    <v-col v-if="article.article_image" cols="12">
                      <v-img
                        :src="article.article_image"
                        height="150px"
                        class="rounded-t-lg"
                      ></v-img>
                    </v-col>
                    <v-col cols="12" class="sw-py-3 sw-px-2">
                      <div
                        :style="{ color: gmPrimaryColor }"
                        class="text-overflow-ellipsis font-weight-bold"
                      >
                        {{ article.name }}
                      </div>
                      <div
                        v-html="article.body"
                        :style="{ color: gmPrimaryColor }"
                        class="text-overflow-ellipsis"
                      ></div>
                    </v-col>
                  </v-row>
                  <div
                    style="width: 100%; position: absolute; bottom: -22px"
                    class="text-center"
                  >
                    <v-btn
                      depressed
                      large
                      rounded
                      :style="{ backgroundColor: gmSecondaryColor }"
                      class="text-none white--text"
                      @click="showArticle(article)"
                    >
                      {{ $vuetify.lang.t("$vuetify.readMore") }}
                    </v-btn>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </transition>
      <div v-if="totalPages > 1" class="text-center">
        <v-pagination
          v-model="page"
          :length="totalPages"
          :total-visible="5"
          @input="listGroupArticles"
          :color="gmSecondaryColor"
        ></v-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    isDataLoaded: false,
    articles: [],
    totalPages: null,
    page: 1,
    perPage: 20,
  }),
  computed: {
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    groupPluginId() {
      return this.$route?.params?.group_plugin_id;
    },
    appLanguage() {
      return this.$store.getters?.appLanguage;
    },
    navigation() {
      const navigation = this.$store?.getters?.navigation?.destinations?.find(
        (el) =>
          el.component === "ArticleListPage" &&
          el.group_plugin_id == this.groupPluginId,
      );
      return navigation || {};
    },
  },
  methods: {
    substringText(value, charactersCount) {
      if (!value) return "";

      if (value.length < charactersCount) {
        return value;
      } else {
        return value.substring(0, charactersCount) + "...";
      }
    },
    articleTransformer(article) {
      return {
        id: article.id,
        name: this.substringText(article.name, 25),
        body: this.substringText(article.body, 40),
        sort_order: article.sort_order,
        group_plugin_id: article.group_plugin_id,
        categories: article.categories,
        publish_at: article.publish_at,
        publish_until: article.publish_until,
        article_image:
          article.attributes && article.attributes.article_image
            ? article.attributes.article_image.presets.h500
            : null,
      };
    },
    listGroupArticles() {
      if (!this.groupId) return;

      this.isDataLoaded = false;

      const params = {
        page: this.page,
        per_page: this.perPage,
        group_plugin_id: this.groupPluginId,
        with_attributes: 1,
        expand_attributes: 1,
        lang: this.appLanguage,
        sort: "created_at:desc",
      };

      this.$http
        .get(`/groups/${this.groupId}/articles`, { params })
        .then((response) => {
          this.isDataLoaded = true;

          if (!response?.data?.data) return;

          this.articles = response.data.data.map((el) =>
            this.articleTransformer(el),
          );

          this.totalPages = response.data.pagination?.total_pages;
        })
        .catch(() => {
          this.isDataLoaded = true;
        });
    },
    showArticle(article) {
      if (!article?.id) return;

      this.$router.push({
        name: "ArticlePage",
        params: {
          group_plugin_id: article.group_plugin_id,
          article_id: article.id,
        },
      });
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route?.query?.page) {
          this.page = Number(this.$route.query.page);
        }

        this.listGroupArticles();
      },
    },
    appLanguage: {
      handler() {
        this.listGroupArticles();
      },
    },
  },
};
</script>

<style scoped lang="scss">
@media (max-width: 900px) {
  .article-list-view {
    padding-top: 130px !important;

    .container {
      padding: 0 30px;
    }

    .col-3 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
</style>
